import React from 'react';

export default function EVBuses() {
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src="https://main.duwdx397totfd.amplifyapp.com/"
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                }}
                title="BRT/EV Bus Routes"
            />
        </div>
    );
};