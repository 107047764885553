import React from 'react';

export default function EVTrains() {
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src="https://main.duwdx397totfd.amplifyapp.com/railway"
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                }}
                title="BRT/EV Train Routes"
            />
        </div>
    );
};